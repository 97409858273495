import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import Dashboard from "../Layout/Dashboard";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { USER_ROLES_DROPDOWN } from "../constants";
import { useQuery } from "@tanstack/react-query";

import {  useNavigate } from "react-router-dom";

import { getPeopleMatchingCI } from "../services/people";

/**
 * User table and management page
 */
export default function PersonListing() {
  // Dialog visibility
  const [showDialog, setShowDialog] = useState(false);
  const [personSearch, setPersonSearch] = useState("");
  const [personType, setPersonType] = useState("V");
  const patientQuery = useQuery({
    queryKey: ["patient", personType + personSearch],
    queryFn: () => getPeopleMatchingCI(personType + personSearch),
    enabled: personSearch.length >= 4,
  });
  const navigate = useNavigate();
  const verifiedBodyTemplate = (rowData) => {
    return (
      <i
        className={classNames("pi", {
          "true-icon pi-check-circle": rowData.verified,
          "false-icon pi-times-circle": !rowData.verified,
        })}
      ></i>
    );
  };
  return (
    <Dashboard headerTitle="Gestionar asegurado">
      <div className="">
        {/* <Button
          icon="pi pi-plus"
          label="Crear usuario"
          className="mb-3"
          type="button"
          style={{ maxWidth: "fit-content" }}
          onClick={() => setShowDialog(true)}
        /> */}
        

        <div className="p-field mb-2">
          <label htmlFor="holderCi">Cédula</label>
          <div className="field-with-select">
            <Dropdown
              inputId="personType"
              name="personType"
              required
              placeholder="Seleccione un tipo de persona"
              value={personType}
              onChange={(e) => setPersonType(e.value)}
              options={[{ label: "V", value: "V" }]}
            />
            <InputText
              placeholder="Buscar por cedula"
              className=""
              onInput={(e) => console.log("Buscando:", e.target.value)}
              style={{ maxWidth: "300px" }}
              value={personSearch}
              onChange={(e) => setPersonSearch(e.target.value)}
            />
          </div>
        </div>
        <DataTable
          emptyMessage="Sin usuarios registrados."
          value={patientQuery.data ?? []}
        >
          <Column field="name" header="Nombre" />
          <Column field="ci" header="cedula" />
          <Column
            field="is_holder"
            header="Titular"
            body={verifiedBodyTemplate}
          />
          <Column
            body={(rowData) => (
              <Button
                className="p-button"
                onClick={() => {
                  navigate("/persona/editar/" + rowData?.id);
                }}
                label="Editar"
              />
            )}
            header="Acciones"
          />
        </DataTable>
      </div>
      <NewUserDialog
        show={showDialog}
        onHide={() => {
          if (showDialog) setShowDialog(false);
        }}
      />
    </Dashboard>
  );
}

function NewUserDialog({ show, onHide }) {
  // Form state
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(null);

  const canSubmit = email.length > 0 && role !== null;

  return (
    <Dialog
      header="Nuevo usuario"
      visible={show}
      onHide={onHide}
      draggable={false}
    >
      <form className="p-fluid">
        <div className="p-field">
          <label htmlFor="email">Correo electrónico</label>
          <InputText
            id="email"
            name="email"
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="role">Rol</label>
          <Dropdown
            inputId="role"
            name="role"
            required
            placeholder="Seleccione un rol"
            value={role}
            onChange={(e) => setRole(e.value)}
            options={USER_ROLES_DROPDOWN}
          />
        </div>
        <Button label="Crear" disabled={!canSubmit} />
      </form>
    </Dialog>
  );
}
